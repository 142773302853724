import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { hourlyRateUsd } from "../../components/Helpers";
import CtaPrimary from "../../components/CtaPrimary";
import FeatureBox from "../../components/FeatureBox";
import { experienceYears } from "../../components/Helpers";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import Img from "../../components/Img.js";
import H from "../../components/Headline";
import ProfileCard from "../../components/ProfileCard";
import ContactForm from "../../components/ContactForm";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import { Helmet } from 'react-helmet'

const breadCrumbLevels = {
  Home: "/",
  "Over mij": "/nl/over",
  "Google Analytics Consultant": "/nl/google-analytics-consultant"
};

// Hreflang data
const alternateLangs = [
  {
    hreflang: "en",
    href: "/en/google-analytics-consultant"
  },
  {
    hreflang: "de",
    href: "/de/google-analytics-consultant"
  },
  {
    hreflang: "se",
    href: "/se/google-analytics-konsult"
  },
  {
    hreflang: "da",
    href: "/da/google-analytics-konsulent"
  },
  {
    hreflang: "no",
    href: "/no/google-analytics-konsulent"
  },
  {
    hreflang: "nl",
    href: "/nl/google-analytics-consultant"
  },
  {
    hreflang: "x-default",
    href: "/en/google-analytics-consultant"
  }
];

const schemaRating = `
"@context": "https://schema.org/",
"@type": "Product",
"name": "Google Analytics Consulting Services",
"description": "Onafhankelijke Google Analytics Expert Matthias Kupperschmidt: Neem contact met mij op voor GA4 Consulting Services en website-analytics. Professionele Google Analytics service met 10 jaar ervaring.",
"brand": {
  "@type": "Brand",
  "name": "Blue River Mountains"
},
"offers": {
  "@type": "AggregateOffer",
  "url": "https://bluerivermountains.com/nl/google-analytics-consultant",
  "priceCurrency": "USD",
  "lowPrice": "110",
  "highPrice": "130"
},
"aggregateRating": {
  "@type": "AggregateRating",
  "ratingValue": "4.6",
  "ratingCount": "21"
}`;


const googleAnalyticsConsultant = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title="Google Analytics Consulting Services"
        description="Onafhankelijke Google Analytics Expert Matthias Kupperschmidt: Neem contact met mij op voor GA4 Consulting Services en website-analytics. Professionele Google Analytics service met 10 jaar ervaring."
        lang="nl"
        canonical="/nl/google-analytics-consultant"
        image="matthias-kupperschmidt-presentation-measurecamp-berlin-2019"
        alternateLangs={alternateLangs}
        pageType="ServicePage"
        datePublished="2024-06-10T04:32:43.188Z"
        dateModified="2024-06-10T06:56:07Z"
      />
      <MainContent article>
        <Img
          src="freelance-data-analyst/matthias-kupperschmidt-presentation-measurecamp-berlin-2019.jpg"
          alt='google analytics expert matthias kupperschmidt presenteren bij founders house, oktober 2019'
        />
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
        <H as="h1">Google Analytics Consulting</H>
        <ProfileCard
          tags={["Google Analytics Setup", "Event Tracking", "Retargeting", "Google Analytics 4", "eCommerce Tracking", "Google Tag Manager", "Cookie Banner", "Consent Mode", "JavaScript", "React", "Python", "EN, DE, DA"]}
          profession="Google Analytics Expert"
          rate={`$${hourlyRateUsd}/uur`}
          location="remote"
          cta="Contact"
          alt="GA4 expert"
        />
        <p>Ik help bedrijven al 10 jaar dagelijks met de planning en aanpassing van hun webtracking!</p>
        <p>Nu, als een <strong>onafhankelijke Google Analytics consultant</strong> met <strong>10 jaar ervaring in web analytics</strong>, kun je mij inhuren om <strong>geavanceerde Google Analytics setups</strong> te ontwikkelen die aangepaste KPI's, eCommerce-transacties meten en naadloos integreren met externe CRM's.</p>
        <p>Ik kan ervoor zorgen dat jouw gegevensverzameling <strong>privacy-compliant</strong> is via moderne <strong>consent management platforms</strong> en een <strong>aangepaste analytics-strategie</strong> ontwikkelen die in lijn is met jouw bedrijfsdoelen.</p>
        <p>Neem contact met mij op voor Google Analytics diensten hieronder - Anders kun je hieronder klant <a target="_blank" noopener="true" href="https://www.trustpilot.com/review/bluerivermountains.com">beoordelingen</a> vinden, mijn <Link to="#consulting-services">consulting diensten</Link>, <Link to="#consulting-rates">tarieven</Link>, en zelfs mijn carrière bekijken op <a href="https://www.linkedin.com/in/matthiaskupperschmidt">LinkedIn</a> of lees meer op mijn <Link to="/nl/over">over mij pagina</Link>.</p>
        <br />
        <H as="h6" style={{ "textAlign": "center" }}>Met wie ik heb gewerkt</H>
        <ImgScreenshot
          src="about/clients_700px.png"
          alt="klanten waar ik mee heb gewerkt"
          className="article-img"
        />
        <br />
        <br />
        <br />

        <H as="h2" style={{ "textAlign": "center" }}>Krijg een gratis consultatie</H>
        <ContactForm showHeadline={false} formName="ga consultant (NL) - contact form" />
        <H as="h2">Huur Google Analytics Bureau Online</H>
        <p>Met Google Analytics kun je je websiteverkeer en bezoekersgedrag analyseren om te bepalen waar gebruikers in geïnteresseerd zijn.</p>
        <p>Deze inzichten stellen je in staat om zakelijke beslissingen te nemen of aangepaste retargetingcampagnes voor je publiek te maken.</p>
        <p>Ons Google Analytics bureau zorgt ervoor dat je de juiste interacties nauwkeurig bijhoudt en dat je rapportageroutine in lijn is met je bedrijfsdoelen.</p>
        <p>In feite is het inhuren van mij als jouw <Link to="/nl/google-analytics-freelancer"><strong>Google Analytics freelancer</strong></Link> sneller, minder stressvol en van betere kwaliteit dan het inhuren van een analytics bureau.</p>

        <H as="h2">Consulting Diensten</H>
        <FeatureBox
          type="tech"
          alt="installatie van een Google Analytics setup"
          headline="Google Analytics Implementatie"
          h="h3"
        >Op maat gemaakte Google Analytics setup geïmplementeerd via Google Tag Manager met event tracking voor alle KPI's van de website. Houd je digitale marketingcampagnes bij en gebruik alle events daarna voor advertenties. Elke analytics-configuratie volgt best practices voor datakwaliteit.</FeatureBox>

        <FeatureBox
          type="search"
          alt="Remarketing"
          headline="Retargeting"
          h="h3"
        >Volg het gedrag van je websitebezoekers om hun interesse te interpreteren. Adverteer je diensten vervolgens op andere advertentieplatformen zoals Facebook, LinkedIn, Instagram, Google Ads, Twitter, Snapchat, TikTok, Pinterest, Bing en meer.</FeatureBox>

        <FeatureBox
          type="check"
          alt="cookie melding"
          headline="Cookie Banner"
          h="h3"
        ><strong>GDPR compliant</strong> cookie pop-up met privacybeheeropties voor bezoekers. Installatie van het consent management platform - optioneel met GTM consent mode.</FeatureBox>

        <FeatureBox
          type="tech"
          alt="Google Data Studio Dashboard"
          headline="Data Studio Dashboard"
          h="h3"
        >Het Data Studio dashboard is de centrale plek om een overzicht te krijgen van conversies uit je Google Analytics gegevens en andere bronnen. Duik diep in gedragsmodellen en ontdek de populairste producten voor je publiek. Elk dashboard heeft een intuïtieve gebruikersinterface en werkt als een deelbaar, interactief rapport. Breng data-inzichten gemakkelijk naar de rest van het bedrijf.</FeatureBox>
        <FeatureBox
          type="check"
          alt="Google Analytics Ecommerce"
          headline="Ecommerce Analytics"
          h="h3"
        >We implementeren verbeterde eCommerce of Google Analytics 4 eCommerce met een data layer om GA-rapporten voor producten, categorieën en transacties te activeren.</FeatureBox>
        <FeatureBox
          type="check"
          alt="audit van analytics configuratie"
          headline="Google Analytics Audit"
          h="h3"
        >Algemene controle van je configuratie op problemen met Google Analytics tracking, conversietoewijzing en transacties. Problemen worden opgelost na goedkeuring van de klant.</FeatureBox>
        <FeatureBox
          type="check"
          alt="server-side GTM"
          headline="Server-Side Google Tag Manager"
          h="h3"
        >Server-Side GTM is een nieuwe oplossing waarmee gegevensverzameling via een backend server mogelijk is om de kwaliteit van je Google Analytics-gegevens te verbeteren. Met <strong>Server-Side Tagging</strong> kun je bezoekers volgen met first-party <Link to="/nl/wiki-analytics/cookies">cookies</Link> of voorkomen dat adblockers Google Analytics blokkeren.</FeatureBox>

        <FeatureBox
          type="search"
          alt="event tracking"
          headline="Aangepaste Event Tracking"
          h="h3"
        >Volg het gedrag van je websitebezoekers om conversies te tellen of retargeting-segmenten te maken. Gebruik productweergaven, contactformulieren of aanmeldingen om inzicht te krijgen in de interesses van je bezoekers.</FeatureBox>

        <H as="h3">Tarieven</H>
        <p>Voor Google Analytics-advies wordt een <strong className="baseline">uurtarief van 120€ per uur</strong> in rekening gebracht. De kosten van consultancydiensten worden geschat op basis van het aantal uren voor een project vermenigvuldigd met het tarief.</p>
        <p>Onze projecten vereisen meestal minder budget in vergelijking met andere bureaus omdat er minder tijd nodig is voor teamcoördinatie.</p>
        <p>Om bedrijven te helpen met vaste prijzen, reken ik analytics-projecten af tegen een vast tarief in plaats van per uur.</p>
        <p>Voor doorlopende Google Analytics-bureaudiensten en -ontwikkeling kunnen bedrijven een maandelijkse <strong>retainer fee</strong> betalen om flexibel een externe specialist beschikbaar te hebben voor hun trackingbehoeften.</p>
        <p><strong>Vergoedingen</strong> of tarieven kunnen worden betaald via bankoverschrijving, met online betaalverwerkers en ook met Bitcoin.</p>

        <H as="h3">Ervaring</H>
        <p>Ik bied betrouwbare Google Analytics-diensten aan met {experienceYears} jaar ervaring. Mijn carrière omvat top digitale bureaus en enterprise-klanten die een hooggekwalificeerde consultant nodig hebben. Je kunt mijn CV bekijken op <a target="_blank" href="https://www.linkedin.com/in/matthiaskupperschmidt">LinkedIn</a> of mijn biografie lezen op mijn <Link to="/nl/over">over mij</Link> pagina om meer te weten te komen.</p>
        <p>Mijn <strong>hard skills</strong> omvatten expertise in Google Analytics, tagbeheer, webontwikkeling en <strong>zoekmachineoptimalisatie</strong>. Ik ben een gecertificeerde Google Analytics-partner met andere certificeringen in digitale analytics en softwareontwikkeling.</p>
        <p>Mijn <strong>competenties</strong> omvatten ook technische vaardigheden zoals coderen, namelijk in JavaScript, Node en Python. Hoewel ik mijn carrière begon aan de marketingkant, ben ik een consultant met een technisch profiel. Aanvullende taalvaardigheden in DE, EN, DA stellen me in staat mijn kennis te delen en in meerdere talen les te geven.</p>
        <p>Mijn persoonlijke vaardigheden omvatten aandacht voor detail, betrouwbaarheid en het vermogen om projecten vooruit te helpen. Maar neem niet alleen mijn woord ervoor en bekijk wat mijn klanten over mij zeggen <a target="_blank" noopener="true" href="https://www.trustpilot.com/review/bluerivermountains.com">hier</a>.</p>
        <p>Alles bij elkaar zorgen mijn opleiding, training en persoonlijke eigenschappen voor een hoge mate van <strong>kwaliteit</strong>. Projecten worden in detail gepland en ik lever professioneel werk.</p>

        <H as="h3">Neem contact met mij op</H>
        <p>Ik ben een <strong>gecertificeerde</strong> Google Analytics-expert met bureauprofessionaliteit. Neem contact met mij op voor Google Analytics-diensten en het opzetten van webtracking.</p>
        <Link to="/nl/contact"><CtaPrimary color="red" arrow="false" align="center">Contact</CtaPrimary></Link>
        <br />
        <p>Je kunt anders mijn carrière bekijken op <a href="https://www.linkedin.com/in/matthiaskupperschmidt">LinkedIn</a>, mijn <a target="_blank" noopener="true" href="https://www.trustpilot.com/review/bluerivermountains.com">klantbeoordelingen</a> lezen of meer over mij te weten komen op mijn <Link to="/nl/over">over mij pagina</Link>.</p>

        <H as="h3">Voordelen van het inhuren van een onafhankelijke consultant in plaats van een bureau</H>
        <ul>
          <li><p>Ik ben meer gefocust. Overdag werk ik aan één tot maximaal twee analytics-projecten tegelijk. Dit houdt mijn geest gefocust op de belangrijke kwesties en voorkomt afleiding.</p></li>
          <li>Mijn klanten benadrukken voortdurend hoe gemakkelijk het is om projecten met mij af te handelen: Wanneer ik informatie moet delen, stuur ik een schermopname die je op je gemak kunt bekijken (in plaats van een lange en ingewikkelde e-mail). Anders vindt communicatie plaats via e-mail of video-oproep.</li>
          <li>Ik houd mezelf op de hoogte van de nieuwste ontwikkelingen in data-analyse en onderhoud contact met web analytics bureaus.<br />Mijn <strong>Google Analytics implementatiediensten</strong> zijn modern en minstens even goed als het werk van bureaus, meestal zelfs beter. In feite doe ik <strong>freelance werk</strong> (white label) voor enkele van de beste web analytics bureaus in Europa.</li>
          <li>Bovendien ben ik geen verkoper die met PowerPoint-presentaties en upsell-tactieken op je afkomt. Ik focus alleen op analytics en ben bondig maar <strong>professioneel</strong> in mijn communicatie.</li>
          <li>Met een onafhankelijke consultant voor GA4 kun je je tijdelijke behoefte aan analytics-kennis invullen zonder een langdurige verplichting. Huur per project volledig op afstand om je tracking op te lossen.</li>
          <li>Bovendien ontvang je met een onafhankelijke consultant een <strong>objectieve analyse</strong> van de prestaties van je campagnes. Vanwege het belangenconflict kan het bureau dat je digitale campagnes uitvoert geen objectieve analyse leveren.</li>
        </ul>

        <H as="h3">Hoe huur je een GA4 consultant in?</H>
        <p>Je kunt GA4-adviesdiensten inhuren door een eerste afspraak te plannen via het contactformulier. Daarna krijg je een offerte en begin ik kort na onze overeenkomst met het project.</p>
        <p>Als <strong>vereiste</strong> heb ik toegang nodig tot GA4-eigenschappen, Google Tag Manager of andere externe trackingplatforms. Zodra ik toegang heb, kan ik aan het project beginnen!</p>

        <H as="h3">Mijn Profiel</H>
        <p>Ik bied de Google Analytics-dienst aan die bureaus niet kunnen evenaren.</p>
        <p>
          Ik bied al jaren <Link to="/nl/analytics-consulting">analytics consulting</Link> aan voor ondernemingen of
          middelgrote eCommerce-websites om een ervaren GA-professional te worden. Of het nu Google Analytics of Adobe Analytics is, het zijn mijn analytics stacks,
          samen met de respectieve tag management systemen en datavisualisatietools.
        </p>

        <p>
          Mijn analytics setups zijn doorgaans geïntegreerd met advertentienetwerken zoals Google Ads, Twitter,
          Facebook Ads en Co.
          <br />Ik werk vaak nauw samen met Google Ads-experts om de beste KPI's te bepalen voor het verbeteren van de
          conversieratio van onze gebruikerssegmenten.
        </p>
        <p>
          Rapportage wordt gedaan met aangepaste rapporten of met dashboards van Klipfolio, Google Data Studio of Power
          BI. Rapportage over de prestaties van SEO-campagnes is een typisch aandachtspunt van dergelijke dashboards. Ze kunnen gegevens
          van verschillende externe tools of gescrapte gegevens voor één enkel rapport bevatten.
        </p>

        <H as="h2">Wat is een Google Analytics Consultant?</H>
        <p>
          Een consultant voor Google Analytics plant en implementeert webtracking setups voor websites. Tracking setups verzamelen cruciale gebruikersinteracties en transacties voor analyse en retargeting.
        </p>
        <p>
          Samen met de klant definieert de consultant een meetplan met daarin alle belangrijkste prestatie-indicatoren (KPI's) die bruikbare inzichten genereren. Het meetplan dient ook als basis voor de implementatie van Google Analytics en maandelijkse rapportage.
        </p>
        <p className="baseline">
          De benodigde tools zijn meestal Google Tag Manager (GTM) en Google Analytics.<br />Google Tag Manager is het populairste Tag Management Systeem, met een <a href="https://trends.builtwith.com/analytics/tag-management/traffic/Entire-Internet" target="_blank">marktaandeel van 94%</a>. Google Analytics is het equivalent in de markt voor verkeersanalysetools, met een <a href="https://w3techs.com/technologies/overview/traffic_analysis" target="_blank">marktaandeel van 84%</a>.
        </p>



        <H as="h2">Typische webanalyseprojecten</H>
        <p>
          Als er een volledig nieuwe Google Analytics setup moet worden geïmplementeerd, kan mijn GA-adviesdienst de taak
          eenvoudig op afstand uitvoeren. Dit kan worden gedaan in samenwerking met een webbureau dat de websitecode beheert.
          Als alternatief, als de broncode toegankelijk is, kan de implementatie hands-on worden uitgevoerd.
        </p>
        <p>
          Wanneer je samenwerkt met Google Analytics-experts, kun je direct communiceren met de expert die de taak uitvoert.
          Dit bespaart tijd en voorkomt misverstanden.
        </p>
        <p>
          Een ander scenario waarin het inhuren van een <Link to="/nl/google-analytics-freelancer">Google Analytics freelancer</Link> zinvol is, is wanneer een Analytics setup moet
          worden uitgebreid.
        </p>
        <p>
          Websites veranderen en groeien voortdurend. Daarom moet de tracking setup ook worden uitgebreid om rekening te houden
          met nieuw toegevoegde functionaliteiten of tracking over meerdere websites.
        </p>
        <p>
          Daarom worden er regelmatig nieuwe KPI's toegevoegd aan de tracking setup. Bijvoorbeeld nieuwe gebruikersformulieren,
          extra aangepaste events of een nieuwe logica voor bezoekerssegmentatie.
        </p>
        <p>
          Website-eigenaren die met retargeting werken, hebben meestal niet alle statistieken uitgewerkt om hun doelgroepen te
          segmenteren wanneer ze voor het eerst beginnen met digitale marketing.
        </p>
        <p>
          Het verfijnen van de tracking voor bezoekerssegmentatie is daarom een typische latere toevoeging. Als gevolg daarvan
          wordt retargeting alleen uitgevoerd op bezoekers die daadwerkelijk serieuze interesse in het product hebben getoond.
          Het verkleint de doelgroep en verhoogt het conversiepercentage. Over het algemeen kunnen alle Google Analytics-events
          of -segmenten worden geconfigureerd om gebruikerssegmenten te maken voor Facebook, Google Adwords of elke andere
          betaalde verkeersbron.
        </p>
        <p>
          Voor eCommerce-websites is dit een gebruikelijke strategie, aangezien bezoekers al door hun productweergaven en
          filterselecties hebben aangegeven waarin ze geïnteresseerd zijn. Daarom richt een digitale marketingstrategie in de
          eCommerce-sector zich vaak op retargetingcampagnes op zeer verfijnde gebruikerssegmenten, vanwege hun relatief hoge
          conversiepercentage.
        </p>
        <p>
          Zodra een eCommerce-winkel efficiënt wordt met hun retargetingstrategie, kunnen ze zelfs meerdere websites in dezelfde
          niche starten, uitsluitend om hun gebruikerssegmenten te vergroten. In dergelijke situaties bereikt consulting voor
          betaalde en programmatic advertising bedrijfskritisch belang.
        </p>
        <p>
          Andere typische Google Analytics-projecten hebben meestal betrekking op problemen met het correct meten van
          gebruikersinteracties of het analyseren van gegevens. In dat geval zorgt een Google Analytics-audit ervoor dat de
          gegevensverzameling naar verwachting verloopt.
        </p>
        <p>
          Een andere veelvoorkomende uitdaging is dat gegevens zich meestal in verschillende datasilo's bevinden, zoals een
          database, CRM of analysesoftware. Om dit op te lossen, integreren analytics consultants datasilo's met elkaar of
          creëren ze een derde, allesomvattende datastore (de zogenaamde "data lake") voor analyse.
        </p>
        <p>
          Zodra de gegevensverzameling soepel verloopt en alle gegevens toegankelijk en analyseerbaar zijn, zijn alle KPI's
          klaar voor rapportage.
        </p>
        <p>
          Een andere overweging is in hoeverre een continu optimalisatie- en testcyclus als proces in het bedrijf moet worden
          geïntroduceerd. Als een bedrijf sterk afhankelijk is van digitale marketing voor zijn bedrijfsdoelen en algehele
          strategie, is het de moeite waard om solide processen te integreren om ervoor te zorgen dat marketingbeslissingen
          gebaseerd zijn op data-inzichten.
        </p>
        <p>
          Al deze scenario's kunnen net zo goed worden beheerd door onafhankelijke Google Analytics-specialisten als door een
          marketingbureau.
        </p>

        <H as="h2">Welke vaardigheden zijn nodig?</H>
        <p>
          Webanalyse begint meestal met een gegevensverzamelingsfase, gevolgd door een analyse- en optimalisatiefase. Het is een cyclus die continu doorgaat. In elke iteratie wordt een hypothese gevalideerd door gegevens en dienovereenkomstig verfijnd.
        </p>
        <p>Analytics-consultancybedrijven verdelen analysetaken meestal in twee verantwoordelijkheden:</p>
        <ul>
          <li>Implementatie voor gegevensverzameling</li>
          <li>Analyse en rapportage</li>
        </ul>
        <p>
          Je kunt bepalen in welke van de gebieden een analysetaak valt. Op basis hiervan kan een profiel voor de optimale Google Analytics-consultants worden gemaakt.
        </p>
        <p>
          Gegevensverzameling is vaak zeer technisch, aangezien de implementatie en "data piping" van verschillende bronnen naar het Google Analytics-account hier worden uitgevoerd.
        </p>
        <p>
          Theoretisch is het ook mogelijk om gegevensbronnen te combineren zonder enige programmering. Dashboardtools kunnen dat tegenwoordig vrij goed. De beperkingen van de verschillende analyse- en rapportagetools bepalen uiteindelijk of de gegevensintegratie wordt gedaan met een dashboardtool of op maat.
        </p>
        <p>
          De rol van gegevensanalyse kan ook zeer technisch worden uitgevoerd of helemaal niet, als de analyse plaatsvindt in een typische web-GUI, zoals met de Google Analytics UI of een dashboardinterface.
        </p>
        <p>
          De vereiste vaardigheden voor de analysrol kunnen daarom variëren van het opvragen van relationele databases tot het ontwerpen van mooie, aangepaste dashboards.
        </p>
        <p>
          Dus waarom heb ik het over de benodigde vaardigheden als het moeilijk is om ze vast te leggen en het lijkt af te hangen van het project? - Het punt is dat er meestal verschillende manieren zijn om een digitaal analytics-probleem op te lossen. Het kan altijd op een gecompliceerde manier worden opgelost, maar vaker wel dan niet kan het eenvoudiger worden gedaan als we compromissen sluiten.{" "}
        </p>
        <p>
          Welke manier de beste is, wordt bepaald op basis van verwachtingen, voorkeuren voor analysetools en uiteindelijk budget. Daarom is het het beste om de analytics-expert verschillende oplossingen te laten voorstellen en vervolgens de oplossing te kiezen die het beste aansluit bij de projectvereisten.{" "}
        </p>

        <H as="h2" style={{ "textAlign": "center" }}>Huur een Google Pro</H>
        <Link to="/nl/contact"><CtaPrimary color="red" arrow="false" align="center">Contact</CtaPrimary></Link>
        <br />
        <br />
        <br />
        {/* <RelatedContent /> */}
        <Helmet>
          <script type="application/ld+json">{schemaRating}</script>
        </Helmet>
      </MainContent>
    </React.Fragment>


  </Layout>
);

export default googleAnalyticsConsultant;
